import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { buttonResetCss, gridContainerCss } from '../../utils';
import BgShape from '../BgShape';
import { gv, ns } from '../../utils';
import { Constrain, GeneralBody } from '../Containers';
import {
	Subtitle,
	Title,
	Container as ArticleContainer,
} from '../Hero/Article';

const Left = styled.div`
	flex: 0 0 100%;
	padding: ${props => ns(props.theme.gutter)};
	min-height: 150px;
`;
const Right = styled.div`
	flex: 0 0 100%;
	padding: ${props => ns(props.theme.gutter)};
`;
const Grid = styled.div`
	${gridContainerCss};
	justify-content: flex-start;
	align-items: flex-start;
	padding: ${props => ns(props.theme.gutter * 2)} 0;
	margin-left: 0;
	margin-right: 0;
	@media screen and (min-width: ${props =>
			ns(props.theme.breakpoints.desktop)}) {
		${Left},
		${Right} {
			flex-basis: 50%;
		}
	}
	position: relative;
`;

const TopButton = styled.button`
	${buttonResetCss};
	background-color: rgba(0, 0, 0, 0.1);
	position: absolute;
	top: ${props => ns(props.theme.gutter * -1)};
	right: ${props => ns(props.theme.gutter)};
	padding: ${props => ns(props.theme.gutter * 0.5)};
	font-size: ${props => ns(props.theme.fz.small1)};
	color: ${gv('textColor')};
	font-family: ${props => props.theme.fontFamilyHeading};
`;

const Container = styled.section`
	color: ${gv('textColor')};
	overflow: hidden;
	${Constrain} {
		position: relative;
	}
`;

export type GeneralHeroProps = {
	left?: ReactNode;
	right?: ReactNode;
	children?: ReactNode;
};
export default function GeneralHero(props: GeneralHeroProps) {
	const { left, right, children } = props;
	return (
		<BgShape
			mode="auto"
			style={{ backgroundColor: gv('backgroundShade') }}
			bgContainerStyle={{ left: '40%' }}
		>
			<Container>
				<Constrain>
					{children ? (
						<GeneralBody className="wide">{children}</GeneralBody>
					) : (
						<Grid>
							<Left>{left}</Left>
							<Right>{right}</Right>
						</Grid>
					)}
				</Constrain>
			</Container>
		</BgShape>
	);
}

GeneralHero.Title = Title;
GeneralHero.Subtitle = Subtitle;
GeneralHero.Article = ArticleContainer;
GeneralHero.TopButton = TopButton;
