import React, { ReactNode } from 'react';
import { PageProps } from 'gatsby';
import styled from 'styled-components';
import { ns } from '../utils';
import DocDataProvider, { useDocData } from '../components/DocDataProvider';
import { docTypes, specialDocs } from '../data/docTypes';
import Seo from '../components/Seo';
import { PageSubTitle, PageTitle } from '../components/Typography';
import Search from '../components/Search';
import NewSupport from '../components/NewSupport';
import GeneralHero from '../components/GeneralHero';
import Card from '../components/Card';
import { Constrain, GeneralBody } from '../components/Containers';

const SearchContainer = styled.div`
	padding: ${props => ns(props.theme.gutter * 2)};
`;

function AllDocCards() {
	const data = useDocData();
	if (!data) {
		return null;
	}
	let cards: ReactNode[] = [];
	specialDocs.forEach(specialDoc => {
		cards.push(
			<Card
				type={specialDoc.type === 'accent' ? 'accent' : 'accent-secondary'}
				className={specialDoc.type}
				key={specialDoc.to}
				to={specialDoc.to}
				layout="max-2"
			>
				<Card.BgIcon>
					<specialDoc.icon />
				</Card.BgIcon>
				<Card.Title>{specialDoc.label}</Card.Title>
				<Card.Description>{specialDoc.description}</Card.Description>
				<Card.FakeButton>{specialDoc.buttonLabel}</Card.FakeButton>
			</Card>
		);
	});
	docTypes.forEach(docType => {
		const queryData = data[docType.accessor];
		cards.push(
			<Card
				type="default"
				key={docType.accessor}
				to={`/docs/${docType.docType}/`}
				layout="max-2"
			>
				<Card.BgIcon>
					<docType.icon />
				</Card.BgIcon>
				<Card.Title>{docType.label}</Card.Title>
				<Card.Subtitle>{`${queryData.edges.length} Article${
					queryData.edges.length === 1 ? '' : 's'
				}`}</Card.Subtitle>
				<Card.Description>{docType.description}</Card.Description>
			</Card>
		);
	});
	return (
		<Constrain className="narrow">
			<GeneralBody>
				<PageTitle className="centered" as="h2">
					Welcome to WPEForm Documentation Center!
				</PageTitle>
				<PageSubTitle className="centered">
					We’ve provided answers to a lot of common questions below so that you
					can learn how to get your tasks completed.
				</PageSubTitle>
				<Card.Grid>{cards}</Card.Grid>
			</GeneralBody>
		</Constrain>
	);
}

export default function Docs(props: PageProps) {
	return (
		<DocDataProvider>
			<Seo
				title="WPEForm Documentation | WordPress Form Builder"
				description="Explore the documentation of WPEForm a no-code WordPress Form Builder."
			/>
			<GeneralHero>
				<PageTitle className="centered" as="h2">
					What can we help you with?
				</PageTitle>
				<SearchContainer>
					<Search searchScope="docs" uiType="big" />
				</SearchContainer>
			</GeneralHero>
			<AllDocCards />
			<NewSupport type="singleDoc" />
		</DocDataProvider>
	);
}
