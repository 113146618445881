import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay';
import { bp, ns } from '../../utils';
import { GradientHeading, Highlight } from '../Typography';
import BgShape from '../BgShape';
import Cta from '../Cta';

export const Title = styled.h1`
	font-family: ${props => props.theme.fontFamilyHeading};
	font-weight: bold;
	font-size: ${props => ns(props.theme.fz.large4)};
	margin: 0 0 ${props => ns(props.theme.gutter * 1.5)} 0;
	text-align: center;
	line-height: 1.2;
	@media screen and (min-width: ${props =>
			ns(props.theme.breakpoints.tablet)}) {
		font-size: ${props => ns(props.theme.fz.large5)};
		line-height: 1.4;
		margin: 0 0 ${props => ns(props.theme.gutter * 2)} 0;
	}
	@media screen and (min-width: ${props =>
			ns(props.theme.breakpoints.desktop)}) {
		text-align: left;
		margin: 0 0 ${props => ns(props.theme.gutter * 2.5)} 0;
	}
`;

export const Subtitle = styled.p`
	font-family: ${props => props.theme.fontFamilyBody};
	font-weight: normal;
	line-height: 1.5;
	font-size: ${props => ns(props.theme.fz.large1)};
	margin: 0;
	text-align: center;
	@media screen and (min-width: ${props =>
			ns(props.theme.breakpoints.tablet)}) {
		font-size: ${props => ns(props.theme.fz.large2)};
	}
	@media screen and (min-width: ${props =>
			ns(props.theme.breakpoints.desktop)}) {
		text-align: left;
	}
`;

export const Container = styled.article`
	max-width: 740px;
	margin: 0 auto;
	padding: ${props => ns(props.theme.gutter / 2)};
	@media ${props => bp(props, 'desktop')} {
		margin: 0;
		padding: ${props => ns(props.theme.gutter * 2)};
		${GradientHeading} {
			text-align: left;
		}
	}
`;

export default function Article(props: {
	setVideoShown: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const { setVideoShown } = props;
	return (
		<>
			<BgShape mode="auto">
				<Container>
					<GradientHeading>
						<span>WordPress Form Builder</span>
					</GradientHeading>
					<Title>
						Start making modern <Highlight>Forms</Highlight> in less than
						<br />
						<Highlight>10 minutes</Highlight>.
					</Title>
					<Subtitle>
						Next generation <strong>Drag and Drop Live Form Builder</strong>{' '}
						with <Highlight>payments</Highlight>, cost estimation,{' '}
						<Highlight>landing page</Highlight>, quizzes,{' '}
						<Highlight>personality tests</Highlight>, surveys, data collection
						and user feedback of all kinds. Create <strong>smarter</strong>{' '}
						&amp; <strong>faster</strong> forms right inside WordPress.
					</Subtitle>
					<Cta.Group size="large" align="center-left-desktop">
						<Cta
							to={() => {
								setVideoShown(true);
							}}
							size="large"
							type="primary"
							aria-label="See WPEForm Introduction Video"
						>
							<FontAwesomeIcon icon={faPlay} />
							<span>Watch Video</span>
						</Cta>
						<Cta
							to="/examples/"
							type="ghost"
							size="large"
							aria-label="See all WPEForm form examples"
						>
							Examples
						</Cta>
					</Cta.Group>
				</Container>
			</BgShape>
		</>
	);
}
