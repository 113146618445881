import classNames from 'classnames';
import { Link } from 'gatsby';
import React from 'react';
import { ReactNode } from 'react';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { bp, getMultipleTransitionsWithWillChange, gv, ns } from '../../utils';
import { CtaGroup, SkewReversal, StyledLink } from '../Cta';
import { NumContainer } from '../Typography/Num';
import { VideoWrapper } from '../YoutubeEmbed';

const Title = styled.h3`
	font-family: ${props => props.theme.fontFamilyHeading};
	font-size: ${props => ns(props.theme.fz.large1)};
	color: inherit;
	margin: 0 0 ${props => ns(props.theme.gutter / 2)};
	line-height: 1.4;
	position: relative;
	${NumContainer} {
		margin: 0;
	}
`;

const Subtitle = styled.p`
	font-family: ${props => props.theme.fontFamilyHeading};
	font-size: ${props => ns(props.theme.fz.small1)};
	margin: 0 0 ${props => ns(props.theme.gutter * 0.5)} 0;
	color: ${gv('textColorSecondary')};
	position: relative;
`;

const Section = styled.div`
	margin: 0 0 ${props => ns(props.theme.gutter * 1.25)} 0;
`;

const Description = styled.p<{ secondary?: boolean }>`
	font-size: ${props =>
		ns(props.secondary ? props.theme.fz.small1 : props.theme.fz.large1)};
	line-height: 1.5;
	margin: 0;
	position: relative;
	margin: 0 0 ${props => ns(props.theme.gutter * 1.25)} 0;
	a {
		color: ${gv('linkColor')};
		text-decoration: underline;
		&:hover {
			color: ${gv('linkHoverColor')};
			text-decoration: none;
		}
	}
`;

const CardInner = styled.div`
	display: flex;
	flex-flow: column nowrap;
	align-self: flex-start;
	justify-self: stretch;
	flex: 1 0 auto;
	> * {
		flex: 0 0 auto;
		&:last-child {
			margin-top: auto;
			margin-bottom: 0;
		}
	}
	padding: ${props => ns(props.theme.gutter)};
	${StyledLink} {
		--box-shadow-offset: 4px;
		--box-shadow-offset-hover: 6px;
		--box-shadow-offset-active: 2px;
	}
	position: relative;
`;

const BgIcon = styled.span`
	position: absolute;
	right: ${props => ns(props.theme.gutter / 2)};
	bottom: ${props => ns(props.theme.gutter / 2)};
	width: 100px;
	height: 100px;
	color: ${gv('disabledBackgroundColor')};
	transition: ${props =>
		getMultipleTransitionsWithWillChange(
			['color'],
			props.theme.transitionControl
		)};
	svg {
		width: 100%;
		height: auto;
		display: block;
		fill: currentColor;
	}
`;

const FakeButton = styled.p`
	margin: ${props => ns(props.theme.gutter)} 0 0 0;
	display: inline-block;
	font-size: ${props => ns(props.theme.fz.base)};
	background-color: ${gv('backgroundControl')};
	border-radius: ${props => ns(props.theme.borderRadiusBase)};
	padding: ${props =>
		`${ns(props.theme.gutter / 2)} ${ns(props.theme.gutter)}`};
	color: ${gv('textColor')};
	transition: ${props =>
		getMultipleTransitionsWithWillChange(
			['background-color'],
			props.theme.transitionControl
		)};
	position: relative;
	width: auto;
	flex: 0 0 auto;
	align-self: flex-start;
`;

const CardTag = styled.span`
	position: absolute;
	background-color: rgb(0 0 0 / 50%);
	height: 180px;
	top: -90px;
	width: 100px;
	right: -50px;
	transform: rotate(-45deg);
	> span {
		transform: rotate(45deg);
		position: absolute;
		bottom: 80px;
		left: 15px;
		font-family: ${props => props.theme.fontFamilyHeading};
		font-weight: bold;
		color: ${gv('primaryBgText')};
		text-shadow: 1px 1px 2px ${gv('greyDarkColor')};
	}
`;

const CardImage = styled.div`
	overflow: hidden;
	width: 100%;
	flex: 0 0 auto;
	align-self: flex-start;
	justify-self: flex-start;
	margin: 0;
	border-bottom: 1px solid ${gv('borderColorLight')};
	.gatsby-image-wrapper {
		transition: ${props =>
			getMultipleTransitionsWithWillChange(
				['transform'],
				props.theme.transitionControl
			)};
		transform: scale(1) rotate(0deg);
	}
	position: relative;
	${VideoWrapper} {
		margin: 0;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
`;

const CardContainer = styled.div`
	--box-shadow-color: ${gv('controlShadowGhost')};
	--bg-color: ${gv('appBackgroundColor')};
	--border-color: ${gv('borderColorLight')};
	--color: ${gv('textColor')};
	box-shadow: ${props => props.theme.boxShadowBottom};
	border-radius: ${props => ns(props.theme.borderRadiusBase * 1.5)};
	overflow: hidden;
	background-color: var(--bg-color);
	color: var(--color);
	border: 1px solid var(--border-color);

	&.has-active-state {
		transition: ${props =>
			getMultipleTransitionsWithWillChange(
				['background-color', 'color', 'border-color', 'box-shadow'],
				props.theme.transition
			)};
		&:hover {
			box-shadow: ${props => props.theme.boxShadow4dp};
			color: var(--color);
			${FakeButton} {
				background-color: ${gv('backgroundHover')};
			}
			${BgIcon} {
				color: ${gv('disabledColor')};
			}
		}
	}
	position: relative;
	font-family: ${props => props.theme.fontFamilyHeading};
	display: flex;
	flex-flow: column nowrap;

	// LAYOUTS
	grid-column: auto / span 6;
	@media ${props => bp(props, 'tablet')} {
		grid-column: auto / span 3;
	}
	@media ${props => bp(props, 'desktopHd')} {
		grid-column: auto / span 2;
		&.layout-max-2 {
			grid-column: auto / span 3;
		}
	}
	&.layout-in-full-body {
		@media ${props => bp(props, 'tablet')} {
			grid-column: auto / span 3;
		}
		@media ${props => bp(props, 'desktop')} {
			grid-column: auto / span 2;
		}
	}
	&.layout-full-width {
		grid-column: auto / span 6;
	}
	&.layout-in-blog-footer {
		@media ${props => bp(props, 'tablet')} {
			grid-column: auto / span 3;
		}
	}

	// DESIGN TYPES
	&.type-accent,
	&.type-accent-secondary {
		--box-shadow-color: ${gv('controlShadowAccent')};
		--bg-color: ${gv('accentColor')};
		--border-color: ${gv('accentColor')};
		--color: ${gv('primaryBgText')};

		${BgIcon} {
			color: rgba(255, 255, 255, 0.1);
		}
		${FakeButton} {
			background-color: rgba(0, 0, 0, 0.3);
			color: ${gv('primaryBgText')};
		}
		&:hover {
			${FakeButton} {
				background-color: rgba(0, 0, 0, 0.5);
				color: ${gv('primaryBgText')};
			}
			${BgIcon} {
				color: rgba(255, 255, 255, 0.2);
			}
		}
	}
	&.type-accent-secondary {
		--box-shadow-color: ${gv('controlShadowAccentSecondary')};
		--bg-color: ${gv('secondaryAccentColor')};
		--border-color: ${gv('secondaryAccentColor')};
	}

	// Modify CTA inside cards
	&&&&&&&& {
		${CtaGroup} {
			${StyledLink} {
				transform: none;
				border-radius: ${props => ns(props.theme.borderRadiusBase)};
				box-shadow: ${props => props.theme.boxShadowBottom};
				&:hover,
				&:active,
				&:focus {
					box-shadow: ${props => props.theme.boxShadow3dp};
				}
				${SkewReversal} {
					transform: none;
				}
				&.rounded {
					transform: none;
					border-radius: ${props => ns(props.theme.borderRadiusBase)};
					box-shadow: ${props => props.theme.boxShadowBottom};
					&:hover,
					&:active,
					&:focus {
						box-shadow: ${props => props.theme.boxShadow3dp};
					}
					${SkewReversal} {
						transform: none;
					}

					&.ghost {
						box-shadow: none;
						&:hover,
						&:active,
						&:focus {
							box-shadow: none;
							background-color: rgba(0, 0, 0, 0.1);
						}
					}
				}
			}
		}
	}
`;

const CardGrid = styled.div`
	display: grid;
	grid-gap: ${props => ns(props.theme.gutter)};
	row-gap: ${props => ns(props.theme.gutter)};
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
`;

export type CardProps = {
	type?: 'default' | 'accent' | 'accent-secondary';
	layout?:
		| 'regular'
		| 'max-2'
		| 'in-full-body'
		| 'full-width'
		| 'in-blog-footer';
	to?: string;
	className?: string;
	image?: ReactNode;
	hasActiveState?: boolean;
};
export default function Card(props: PropsWithChildren<CardProps>) {
	const {
		children,
		type = 'default',
		layout = 'regular',
		to,
		className,
		image,
		hasActiveState = true,
	} = props;
	let additionalProps = {};
	if (to) {
		additionalProps = { as: Link, to };
	}
	return (
		<CardContainer
			className={classNames(
				`type-${type}`,
				`layout-${layout}`,
				{ 'has-active-state': hasActiveState || !!to },
				className
			)}
			{...additionalProps}
		>
			{image ? <CardImage>{image}</CardImage> : null}
			<CardInner>{children}</CardInner>
		</CardContainer>
	);
}
Card.Grid = CardGrid;
Card.Title = Title;
Card.Subtitle = Subtitle;
Card.Section = Section;
Card.BgIcon = BgIcon;
Card.FakeButton = FakeButton;
Card.Description = Description;
Card.Tag = CardTag;
